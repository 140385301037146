<template>
  <div class="page-layout-wrapper">
    <div class="container">
        <AdminHeader v-if="isAdmin" />

        <PageHeader
            :title="uploading ? 'Uploading Song...' : 'Upload Song'"
        />

        <div v-if="uploading"  class="row">
            <div class="col-12">
                <Loader :minheight="true"/>
            </div>
        </div>

        <div v-else>
            <form :class="{'was-validated': wasValidated}" class="mt-5 mb-5 flex-grow-1 d-block">

                <!-- Song Template -->

                <div
                    v-for="(song,index) in songsToUpload"
                    :key="'song-'+index"
                    class="form-song mb-5"
                >
                    <div class="d-flex justify-content-between mb-5" v-if="index > 0">
                        <h2 v-text="`Track #${index + 1}`" />
                        <div>
                            <button v-if="index !== 0" @click="removeSong(index)" type="button" class="btn btn-danger">Remove</button>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Song Title</label>
                        <input required v-model="song.title" type="text" class="form-control">
                        <div class="invalid-feedback">Song title is required</div>
                    </div>
                    <div class="form-group">
                        <label>Artist Name</label>
                        <input required v-model="song.artist" type="text" class="form-control">
                        <div class="invalid-feedback">Artist name is required</div>
                    </div>

                    <div v-if="index === 0" class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input v-model="sameMetaForAll" class="custom-control-input" type="checkbox" id="same_meta">
                            <label class="custom-control-label" for="same_meta">
                                Same for all songs
                            </label>
                        </div>
                    </div>

                    <h4 class="mt-5">Song File</h4>
                    <div class="form-group">
                        <input accept=".mp3, .wav" required :data-index="index" @change="songFileUpload" type="file" id="inputGroupFile02">
                        <div class="invalid-feedback">Song file is required</div>

                        <div v-if="song.file" class="text-center" v-text="song.file.name" />
                    </div>

                    <div v-if="index === 0 || !sameMetaForAll">
                        <h4 class="mt-5">Master Owners</h4>
                        <div class="form-row" v-for="(master_owner, moIndex) in song.master_owners" :key="'master_owner-'+moIndex">
                            <div class="form-group col-md-4">
                                <label v-if="moIndex === 0">Name</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="master_owner.name" type="text" class="form-control" >
                                <div class="invalid-feedback">Master Owner name is required</div>
                            </div>
                            <div class="form-group col-md-4">
                                <label v-if="moIndex === 0">Email</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="master_owner.email" type="text" class="form-control" >
                                <div class="invalid-feedback">Master Owner email is required</div>
                            </div>
                            <div class="form-group col-md-2">
                                <label v-if="moIndex === 0">Split % (Max 100 Total)</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="master_owner.splitPercent" type="number" min="1" max="100" class="form-control">
                                <div class="invalid-feedback">Master Owner split % must be between 1 and 100</div>
                            </div>
                            <div class="form-group col-md-2">
                                <label v-if="moIndex === 0" class="d-block">&nbsp;</label>
                                <button @click="removeMasterOwner(index, moIndex)" type="button" class="btn btn-danger btn-block">Remove</button>
                            </div>
                        </div>
                        <button @click="addMasterOwner(index)" type="button" class="btn">+ Add Master Owner</button>

                        <h4 class="mt-5">Song Writers</h4>
                        <div class="form-row" v-for="(writer, wIndex) in song.writers" :key="'writer-'+wIndex">
                            <div class="form-group col-md-4">
                                <label v-if="wIndex === 0">Name</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="writer.name" type="text" class="form-control">
                                <div class="invalid-feedback">Writer name is required</div>
                            </div>
                            <div class="form-group col-md-4" >
                                <label v-if="wIndex === 0">Email</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="writer.email" type="text" class="form-control">
                                <div class="invalid-feedback">Writer email is required</div>
                            </div>
                            <div class="form-group col-md-2" >
                                <label v-if="wIndex === 0">Split % (Max 100 Total)</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="writer.splitPercent" type="number" min="1" max="100" class="form-control">
                                <div class="invalid-feedback">Writer split % must be between 1 and 100</div>
                            </div>
                            <div class="form-group col-md-2">
                                <label v-if="wIndex === 0" class="d-block">&nbsp;</label>
                                <button @click="removeWriter(index, wIndex)" type="button" class="btn btn-danger btn-block">Remove</button>
                            </div>
                        </div>
                        <button @click="addWriter(index)" type="button" class="btn">+ Add Writer</button>

                        <h4 class="mt-5">Publishers</h4>
                        <div class="form-row" v-for="(publisher, pIndex) in song.publishers" :key="'publisher-'+pIndex">
                            <div class="form-group col-md-4" >
                                <label v-if="pIndex === 0">Name</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="publisher.name" type="text" class="form-control" >
                                <div class="invalid-feedback">Publisher name is required</div>
                            </div>
                            <div class="form-group col-md-4" >
                                <label v-if="pIndex === 0">Email</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="publisher.email" type="text" class="form-control" >
                                <div class="invalid-feedback">Publisher email is required</div>
                            </div>
                            <div class="form-group col-md-2" >
                                <label v-if="pIndex === 0">Split % (Max 100 Total)</label>
                                <input :required="index === 0 || !sameMetaForAll" v-model="publisher.splitPercent" type="number" min="1" max="100" class="form-control">
                                <div class="invalid-feedback">Publisher split % must be between 1 and 100</div>
                            </div>
                            <div class="form-group col-md-2">
                                <label v-if="pIndex === 0" class="d-block">&nbsp;</label>
                                <button @click="removePublisher(index, pIndex)" type="button" class="btn btn-block btn-danger">Remove</button>
                            </div>
                        </div>
                        <button @click="addPublisher(index)" type="button" class="btn">+ Add Publisher</button>
                    </div>
                </div>

                <div class="text-center mb-5">
                    <button @click="addSong()" type="button" class="btn">Add Another Track</button>
                </div>

                <div class="border border-dark agreement mx-auto p-4 mb-5">
                    <p>This “Agreement” is between .Wav Pool LLC ("The Agency"), whose address is 5316 North Figueroa Street, Los Angeles, California 90042, United States and the copyright holder of musical works outlined herein (“The Artist”).</p>

                    <p>THEREFORE, for good and valuable consideration of the mutual covenants and undertakings herein contained, it is hereby agreed by the parties as follows:</p>

                    <p>A. SERVICES</p>

                    <p>1. The Agency will use reasonable efforts to secure licensing opportunities for the songs (including master recordings and compositions) listed on “Schedule A” attached hereto and incorporated hereby (individually a “Work” and collectively the “Works”) throughout the world (“Territory”). The Works shall include, without limitation, all instrumental versions.</p>

                    <p>2. Works will be presented to The Agency's established contacts including, but not limited to, music supervisors, film, television and video game producers, advertising agencies, and private companies ("Client", collectively, “Clients”) that may, as determined by Clients in their sole discretion, have an interest in licensing any of the Works, for use in any media now known or hereafter developed, including, but not limited to, motion pictures, television programs, advertisements, digital campaigns, video content streamable via the internet, and all mobile communications (“Media”).</p>

                    <p>3. The Agency shall collect any revenues generated through The Agency’s placement of the Works in Media, subject to the terms of Section C below, including, but not limited to, fees generated by any license of The Artist’s Work or Works.</p>

                    <p>B. RIGHTS GRANTED</p>

                    <p>According to the terms outlined herein, The Artist hereby grants The Agency the worldwide, perpetual, non-exclusive license and right to:</p>

                    <p>1. Administer, protect, deal in and with the Works including, without limitation, the right to lease, or license the Works throughout the Territory in perpetuity.</p>

                    <p>2. Issue performance, synchronization and master use licenses for the Works in the name and on behalf of The Artist pursuant to this Agreement without prior notice to The Artist.</p>

                    <p>3. Use The Artist’s name, photograph, likeness, and approved biographical material in connection with the Works and the rights granted herein.</p>

                    <p>4. License or assign any of the rights granted to The Agency hereunder and enter into agreements with any person or entity with respect to the same. These rights may be granted by any physical, digital, up-loadable, file in any format and via web form.</p>

                    <p>5. Embed, stream, and advertise any of The Artist’s Works publicly on The Agency's website and a portion thereof with password protected access to file downloads and saving by Client only.</p>

                    <p>C. PAYMENT</p>

                    <p>1. A sum received by The Agency from a Client as a result of a license issued by The Agency to a Client for the use of any single Work listed hereunder ("Licensing Fee"), shall be split by percentage, between The Agency and The Artist based on the amount of each Licensing Fee as outlined below (with the exception of Section C, Paragraph 2). </p>

                    <p>Licensing Fee Amount / Percentage paid to The Artist for the exploitation of each Work or licenses granted by The Agency shall be as follows:</p>

                    <p>0-$5000 / 50%</p>

                    <p>$5001-$10000 / 60%</p>

                    <p>$10001 or more / 70%</p>

                    <p>2. In the event an Artist delivers a pending non Client licensing deal, negotiation, or otherwise, to an Agency representative for the purposes of further negotiation and / or finalizing the license using The Agency's resources, The Artist shall be entitled to 100% of the fee derived from that first license. The Artist shall receive 75% of the fee derived from the same non Client, for any further licenses of The Artist Works only.</p>

                    <p>3. Unless requested by The Client, The Agency shall not purposely separate the terms of any single license so that it may constitute multiple licenses hereby changing the license fee amount negatively impacting The Artist's share. The Agency will however, seek to maximize the fee amounts per type of license in all transactions. </p>

                    <p>4. The Agency shall deliver a payment due to The Artist within sixty (60) days following any Licensing Fees received by The Agency according to the terms herein, and as a result of granting any licenses related to The Artists music ("Payout"). The Artist or a certified public accountant duly authorized by The Artist shall have the right to examine and inspect The Agency’s books and records with respect to revenue due hereunder at The Agency’s principal office during normal business hours and upon reasonable prior written notice, not more than once during any calendar year and only once with respect to any accounting period. </p>

                    <p>5. A Payout shall not include royalties gathered by any Performance Rights Organization (PRO). The Artist shall be responsible for administering their Works with regards to such royalty payments.</p>

                    <p>D. DELIVERY</p>

                    <p>1. Upon execution of this Agreement, The Artist shall deliver to The Agency a digital copy of each Work, on CD or in such other format as The Agency may request. </p>

                    <p>2. Any additional Works may be added to Schedule A with a substitute or amended Schedule “A” signed by The Artist and a designated agent of The Agency. Upon the addition of any additional Works, The Artist shall deliver to The Agency a digital copy of each such Work as provided for herein.</p>

                    <p>3. The Artist shall deliver to The Agency the names and contact information of copyright holder of each work. Upon receipt of any Work from The Artist, The Agency has the sole discretion to determine whether such Work will be included for current and future licensing opportunities.</p>

                    <p>E. CONFLICTING AGREEMENTS</p>

                    <p>1. If at any time during the Term The Artist receives an offer to enter into an exclusive agreement with any third party for the sale, assignment, administration, or other encumbrance or transfer of any of the Works, The Artist will send notice to The Agency immediately thereafter.</p>

                    <p>F. TERM, TERMINATION and TERRITORY</p>

                    <p>1. The Term of this Agreement shall commence on the Effective Date and shall continue until sixty (60) days after either party notifies the other in writing of its intent to terminate (“Term”).</p>

                    <p>2. During the Term, The Artist may terminate The Agency’s rights to any particular Work by notifying The Agency thereof in writing. Any such termination or notification shall be effective sixty (60) days after The Agency receives such notice.</p>

                    <p>3. For the avoidance of doubt, no termination of this Agreement or The Agency’s rights to any particular Work shall terminate or affect any rights granted to any Client hereunder. Any Payouts owed to The Artist will be paid within (90) days of notice to terminate. </p>

                    <p>G. REPRESENTATIONS AND WARRANTIES</p>

                    <p>The Artist hereby represents, warrants and covenants that:</p>

                    <p>1. The Artist has the full right, power and authority to enter into this Agreement. The Artist has the full right, power and authority to provide The Agency and its Clients with all rights to the Works and any other material provided by The Artist hereunder and to grant the rights granted to The Agency hereunder.</p>

                    <p>2. Neither the Works, nor any materials submitted by The Artist hereunder, nor any portion thereof, will infringe upon the right of any third party nor violate any applicable laws or regulations, including, without limitation, copyright, trademark, obscenity, privacy or defamation laws.</p>

                    <p>3. Works and all portions thereof are exclusively The Artist’s original work and do not include any third-party samples, interpolations or other materials.</p>

                    <p>4. The Artist shall pay in full any other artist, performer, publisher or other person or entity due any monies or other consideration in connection with the creation, production, exploitation or use of the Works hereunder and The Agency shall not owe any funds to any third party for the foregoing.</p>

                    <p>5. The Artist has not heretofore sold, assigned, licensed, granted, or encumbered the Works or any portion thereof in any way that may affect or impair the rights, licenses and privileges granted to The Agency hereunder and The Artist shall not hereafter sell, assign, license, grant, or encumber the rights, licenses or privileges granted to The Agency hereunder in any way that may affect or impair The Agency’s rights, licenses or privileges hereunder except as otherwise provided for herein.</p>

                    <p>6. In the event that The Agency procures a license for any of the Works to a specific Client, The Artist shall not license any Work or any other of The Artist’s musical compositions or recordings to that Client during the Term or for eighteen (18) months thereafter anywhere in the Territory, unless that particular client has licensed works from The Artist prior to the execution of this agreement.</p>

                    <p>7. The Artist hereby appoints The Agency as The Artist’s limited attorney-in-fact (this appointment being coupled with an interest) to execute such documents on The Artist’s behalf in furtherance of the intents and purposes of this Agreement without notice to The Artist.</p>

                    <p>H. INDEMNIFICATION</p>

                    <p>1. The Artist hereby defends, indemnifies and holds The Agency and all of The Agency’s Clients, successors and assigns, harmless from and against any and all claims, liabilities, damages and expenses, including court costs and reasonable attorneys’ fees, arising from the Works or any use of the Works, any other material provided by The Artist hereunder, and any portion thereof including, but not limited to, any breach of the representations or warranties herein, or any act or omission by The Artist.</p>

                    <p>2. For the avoidance of doubt, The Artist shall be unequivocally liable for allowing The Agency to license Works that contain third party musical samples, parts, chops, etc., and all legal disputes will be the sole burden of The Artist. If The Artist does not remedy any such legal action, The Agency's remedies may include legal action against The Artist for damages incurred as a result of such legal disputes.</p>

                    <p>I. MEDIATION</p>

                    <p>1. If a dispute arises under this Agreement that cannot be resolved by the good faith negotiation of the parties, the parties agree to submit the dispute to a mutually agreed upon mediator in Los Angeles, CA. Any resulting decision may include the award of reasonable attorneys’ fees and costs.</p>

                    <p>J. MISCELLANEOUS</p>

                    <p>1. This Agreement constitutes the entire agreement between the parties hereto relating to the subject matter hereof and supersedes all prior or contemporaneous understandings, promises, and undertakings, if any, made orally or in writing by or on behalf of the parties with respect to the subject matter hereof. No modification, amendment, waiver, termination, or discharge of any provision hereof shall be binding upon the parties unless confirmed in a writing signed by both parties.</p>

                    <p>2. No waiver by any party of any right under this Agreement shall be construed as a waiver of any other right or privilege hereunder. If a court finds any provision of this Agreement invalid or unenforceable as applied to any circumstance, that provision shall be interpreted so as best to effect the intent of the parties and the remainder of this Agreement shall remain in full force and effect. This Agreement shall be governed by and interpreted in accordance with the laws of California and the United States. The parties hereby consent to the exclusive jurisdiction of the courts located in Los Angeles, CA. The provisions of this Agreement shall be binding upon the successors and assigns of the parties.</p>

                    <p>3. The Agency may, at its election, assign this Agreement or assign or license any of its rights hereunder.</p>

                    <p>4. All royalties due shall be sent to The Artist at its address first set forth above or to such other address as such party hereafter designates by notice in writing to the other. All notices shall be in writing, including royalty statements, and will be sent by email only.</p>

                    <p>5. No failure by The Agency to perform any of its obligations hereunder shall be deemed a breach hereof unless The Artist gives The Agency written notice of such failure and The Agency fails to cure same within thirty (30) days after The Agency’s receipt of such notice.</p>

                    <p>6. The Artist shall have the status of an independent contractor hereunder, and nothing herein contained shall constitute or contemplate The Artist as The Agency's agent or employee.</p>

                    <p>7. The Artist’s remedies hereunder shall be limited to an action at law for damages, if any, and under no circumstances shall The Artist seek or be entitled to injunctive or other equitable relief for any reason pertaining to this Agreement or the Works nor shall The Artist seek or be entitled to enjoin or restrain any reproduction, distribution, use, exploitation or derivative works of the Works provided for herein.</p>

                    <p>8. Each party acknowledges that it has had time and opportunity to review the foregoing and obtain counsel of its choice and advice as deemed necessary or desirable.</p>

                    <p>By entering the information below you are agreeing to the terms set forth within.</p>
                </div>

                <div class="col-md-6 mx-auto mb-4">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input required v-model="agreeToTerms" class="custom-control-input" type="checkbox" id="agree_check_1">
                            <label class="custom-control-label" for="agree_check_1">
                                I AGREE TO THE TERMS SET FORTH IN THE ABOVE AGREEMENT.
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input required v-model="ownOrControl" class="custom-control-input" type="checkbox" id="agree_check_2">
                            <label class="custom-control-label" for="agree_check_2">
                                I APPROVE OF THE RIGHTS APPORTIONED IN THE RIGHTS OWNERS SECTION ABOVE.
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input required v-model="songsAreSubject" class="custom-control-input" type="checkbox" id="agree_check_3">
                            <label class="custom-control-label" for="agree_check_3">
                                I UNDERSTAND THAT THE SONGS I AM UPLOADING ARE SUBJECT TO THE TERMS OF THIS AGREEMENT.
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Your Email</label>
                        <input required v-model="uploader" type="email" class="form-control">
                        <div class="invalid-feedback">Writer name is required</div>
                    </div>
                    <div class="text-center">
                        <button @click="save()" type="button" class="btn btn-primary">UPLOAD Song</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import PageHeader from '@/components/Page/PageHeader'
import AdminHeader from '@/components/Dashboard/AdminHeader'

export default {
  name: 'Upload',
  components: {
    Loader,
    PageHeader,
    AdminHeader
  },
  data: () => ({
    uploading: false,
    uploader: '',
    wasValidated: false,
    agreeToTerms: false,
    ownOrControl: false,
    songsAreSubject: false,
    sameMetaForAll: false,
    songsToUpload: [
      {
        title: '',
        artist: '',
        master_owners: [
          {
            name: '',
            email: '',
            splitPercent: 100
          }
        ],
        writers: [
          {
            name: '',
            email: '',
            splitPercent: 100
          }
        ],
        publishers: [
          {
            name: '',
            email: '',
            splitPercent: 100
          }
        ],
        file: ''
      }
    ]
  }),
  computed: {
    ...mapGetters('auth', [
      'user', 'isAdmin'
    ])
  },
  props: [],
  watch: {},
  methods: {
    addSong () {
      const song = {
        title: '',
        artist: '',
        writers: [
          {
            name: '',
            splitPercent: 100
          }
        ],
        master_owners: [
          {
            name: '',
            email: '',
            splitPercent: 100
          }
        ],
        publishers: [
          {
            name: '',
            splitPercent: 100
          }
        ]
      }

      this.songsToUpload.push(song)
    },
    addWriter (songIndex) {
      const writer = {
        name: '',
        email: '',
        splitPercent: 1
      }
      this.songsToUpload[songIndex].writers.push(writer)
    },
    removeWriter (songIndex, wIndex) {
      this.songsToUpload[songIndex].writers.splice(wIndex, 1)
    },
    addPublisher (songIndex) {
      const publisher = {
        name: '',
        email: '',
        splitPercent: 0
      }
      this.songsToUpload[songIndex].publishers.push(publisher)
    },
    removePublisher (songIndex, pIndex) {
      this.songsToUpload[songIndex].publishers.splice(pIndex, 1)
    },
    addMasterOwner (songIndex) {
      const masterOwner = {
        name: '',
        email: '',
        splitPercent: 1
      }
      this.songsToUpload[songIndex].master_owners.push(masterOwner)
    },
    removeMasterOwner (songIndex, moIndex) {
      this.songsToUpload[songIndex].master_owners.splice(moIndex, 1)
    },
    songFileUpload (event) {
      this.songsToUpload[event.target.dataset.index].file = event.target.files[0]
    },
    removeSong (index) {
      this.songsToUpload.splice(index, 1)
    },
    validateSplitPercentages () {
      // Future logic for determining if percentages are equal to 100% max

      this.wasValidated = true
      return true
    },
    save () {
      this.uploading = true
      if (this.validateSplitPercentages()) {
        var formData = new FormData()

        this.songsToUpload.forEach((song, index) => {
          let masterIndex = index
          if (this.sameMetaForAll) {
            masterIndex = 0
          }
          for (const key in song) {
            if (key == 'file') {
              formData.append('songFiles[]', song.file)
            } else if (key == 'writers' || key == 'publishers') {
              formData.append('songs[' + index + '][' + key + ']', JSON.stringify(this.songsToUpload[masterIndex][key]))
            } else {
              formData.append('songs[' + index + '][' + key + ']', JSON.stringify(song[key]))
            }
          }
        })
        formData.append('uploader', this.uploader)
        const that = this
        this.$http.post('/songs/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(re => {
          if (re.data.status && re.data.status == 'OK') {
            that.$vs.notification({
              position: 'top-center',
              title: 'Success!',
              text: 'Upload successful'
            })
            that.$router.push({ path: '/admin/songs' })
          } else {
            console.log(re)
            that.$vs.notification({
              position: 'top-center',
              title: 'Whoops, something went wrong',
              text: 'check the console'
            })
          }
          that.uploading = false
        }).catch(err => {
          console.log(err)
          that.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong',
            text: 'check the console'
          })
          that.uploading = false
        })
      } else {
        this.uploading = false
      }
    }
  },
  created () {
    if (this.user && this.user.email) {
      this.uploader = this.user.email
    }
  }
}
</script>
<style scoped>
.form-song {
    padding: 22px;
    border-radius: 5px;
    background-color: #f5f5f5;
}
    .margin-top-n1px{ margin-top:-1px; }
    .remove-button{
        padding:3px 12px;
        max-height:34px;
    }
    /*
    .meta-section{
        transition:height .5s ease;
        overflow:hidden;
    }
    */
    .custom-file-label:after{display:none !important}
    .custom-file{
        max-width: 150px;
        margin: auto;
        text-align: center;
    }
    .custom-file-label{border-radius:4px;}
    .agreement{
        width:100%;
        max-width:850px;
        max-height:400px;
        overflow:auto;
    }
    .checkbox-wrapper{
        width:100%;
        max-width:850px;
    }
    .was-validated .form-control:invalid{
        background-color:rgba(220, 53, 69, .15);
    }
</style>
