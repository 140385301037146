<template>
    <div :class="[{'minheight': minheight},'loader']">
        <div class="loader-back">
        </div>
        <div class="loader-front">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Loader',
    data: () => ({
    }),
    computed: {
    },
    watch: {
    },
    props: ['minheight'],
    methods: {
    },
    created() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
.loader {
    display: flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.loader.minheight {
    min-height: 400px;
}
.loader-front {
    width: 50px;
    height: 50px;
}
.loader-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    opacity: .8;
}
</style>